<template>
  <div class="resumeInfo_container global_full_screen">
    <!-- <itemScroll ref="itemScroll"> -->
    <div>
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>专家姓名
        </div>
        <div class="resumeInfo_value global_text_input">
          <!-- <el-input
              v-model="data.name"
              placeholder="请输入专家姓名"
              :disabled="modifyShow['name']"
            ></el-input>
            <el-button size="small" v-if="data.name" @click="doModify('name')"
              >修改</el-button
            > -->

          <el-input
            v-model="data.name"
            placeholder="请输入专家姓名"
            :disabled="!modifyShow['name'].content"
          ></el-input>
          <el-button
            size="small"
            v-if="!modifyShow['name'].isUpdate && modifyShow['name'].button"
            @click="modify('name')"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['name'].isUpdate">
          <div class="update_button" @click="cancleUpdate('name')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('name')">保存</div>
        </div>
      </div>
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>
          专家省份<span class="resumeInfo_text_gray"></span>
        </div>
        <div class="resumeInfo_value global_text_input">
          <el-select
            v-model="data.province"
            placeholder="请选择所在省份"
            @change="changeProvince()"
            :disabled="!modifyShow['province'].content"
          >
            <el-option
              v-for="(item, index) in provinces"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-button
            size="small"
            v-if="
              !modifyShow['province'].isUpdate && modifyShow['province'].button
            "
            @click="modify('province')"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['province'].isUpdate">
          <div class="update_button" @click="cancleUpdate('province')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('province')">
            保存
          </div>
        </div>
      </div>
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>
          专家城市<span class="resumeInfo_text_gray"></span>
        </div>
        <div class="resumeInfo_value global_text_input">
          <el-select
            v-model="data.city"
            placeholder="请选择所在城市"
            :disabled="!modifyShow['city'].content"
          >
            <el-option
              v-for="(item, index) in citys"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-button
            size="small"
            v-if="!modifyShow['city'].isUpdate && modifyShow['city'].button"
            @click="modify('city')"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['city'].isUpdate">
          <div class="update_button" @click="cancleUpdate('city')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('city')">保存</div>
        </div>
      </div>
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>专家医院
        </div>
        <div class="resumeInfo_value global_text_input">
          <el-input
            v-model="data.hospital"
            placeholder="请输入专家医院"
            :disabled="!modifyShow['hospital'].content"
          ></el-input>
          <el-button
            v-if="
              !modifyShow['hospital'].isUpdate && modifyShow['hospital'].button
            "
            @click="modify('hospital')"
            size="small"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['hospital'].isUpdate">
          <div class="update_button" @click="cancleUpdate('hospital')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('hospital')">
            保存
          </div>
        </div>
      </div>
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>专家科室
        </div>
        <div class="resumeInfo_value global_text_input">
          <el-input
            v-model="data.department"
            placeholder="请输入专家科室"
            :disabled="!modifyShow['department'].content"
          ></el-input>
          <el-button
            v-if="
              !modifyShow['department'].isUpdate &&
              modifyShow['department'].button
            "
            size="small"
            @click="modify('department')"
            >修改</el-button
          >
        </div>
        <div
          class="update_button_group"
          v-if="modifyShow['department'].isUpdate"
        >
          <div class="update_button" @click="cancleUpdate('department')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('department')">
            保存
          </div>
        </div>
      </div>
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>手机号
        </div>
        <div class="resumeInfo_value global_text_input">
          <el-input
            v-model="data.phone"
            placeholder="请输入手机号"
            :disabled="!modifyShow['phone'].content"
          ></el-input>
          <el-button
            v-if="!modifyShow['phone'].isUpdate && modifyShow['phone'].button"
            @click="modify('phone')"
            size="small"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['phone'].isUpdate">
          <div class="update_button" @click="cancleUpdate('phone')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('phone')">保存</div>
        </div>
      </div>
      <div class="resumeInfo_row">
        <div
          class="resumeInfo_key"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>
            <span class="global_red_point">*</span>专家简历照片<span
              class="resumeInfo_text_gray"
              >(请上传近期真实照片作为简历页头像)
              <!-- 示例：主任医师、博导、硕导、教授等 -->
            </span>
          </div>

          <el-button
            size="small"
            v-if="
              !modifyShow['profile'].isUpdate && modifyShow['profile'].button
            "
            @click="modify('profile')"
            >修改</el-button
          >
        </div>
        <!-- <div class="resumeInfo_note">
          简历图片(请确保是近期照片,如不是,请重新上传)
        </div> -->

        <div class="doctor_profile">
          <img
            :src="require('@/assets/images/icon/resume_img.png')"
            v-if="!data.profile"
            alt=""
            :class="{ doctor_background: !data.profile }"
            @click="selectFile('profile')"
          />
          <img :src="data.profile" @load="initScroll" v-else alt="" />
        </div>
        <div class="update_button_group" v-if="modifyShow['profile'].isUpdate">
          <div class="update_button" @click="cancleUpdate('profile')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('profile')">
            保存
          </div>
        </div>
      </div>

      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          专家行政职务<span class="resumeInfo_text_gray">
            <!-- （示例：副主任等） -->
          </span>
        </div>
        <div class="resumeInfo_value global_text_textarea">
          <el-input
            v-model="data.job"
            placeholder="请输入专家简历行政职务"
            type="textarea"
            :rows="2"
            @blur="onblur"
            @focus="onfocus"
            :disabled="!modifyShow['job'].content"
          ></el-input>
          <el-button
            v-if="!modifyShow['job'].isUpdate && modifyShow['job'].button"
            @click="modify('job')"
            size="small"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['job'].isUpdate">
          <div class="update_button" @click="cancleUpdate('job')">取消修改</div>
          <div class="update_button" @click="doCommitSingle('job')">保存</div>
        </div>
      </div>
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>
          专家职称<span class="resumeInfo_text_gray"
            >（仅邀请副高及以上专家参与）
          </span>
        </div>
        <div class="resumeInfo_value global_text_input">
          <el-select
            v-model="data.resume"
            placeholder="请选择专家职称"
            :disabled="!modifyShow['resume'].content"
          >
            <el-option
              v-for="(item, index) in resumeOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-button
            size="small"
            v-if="!modifyShow['resume'].isUpdate && modifyShow['resume'].button"
            @click="modify('resume')"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['resume'].isUpdate">
          <div class="update_button" @click="cancleUpdate('resume')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('resume')">
            保存
          </div>
        </div>
      </div>
      <!-- <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>专家职称<span
            class="resumeInfo_text_gray"
          >
            （文献精读仅可副高及以上的专家参加）
          </span>
        </div>
        <div class="resumeInfo_value global_text_textarea">
          <el-input
            v-model="data.resume"
            type="textarea"
            :rows="2"
            @blur="onblur"
            @focus="onfocus"
            placeholder="请输入专家职称"
            :disabled="!modifyShow['resume'].content"
          ></el-input>
          <el-button
            v-if="!modifyShow['resume'].isUpdate && modifyShow['resume'].button"
            @click="modify('resume')"
            size="small"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['resume'].isUpdate">
          <div class="update_button" @click="cancleUpdate('resume')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('resume')">
            保存
          </div>
        </div>
      </div> -->
      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <!-- <span class="global_red_point">*</span> -->
          其他职位<span class="resumeInfo_text_gray"></span>
        </div>
        <div class="resumeInfo_value global_text_textarea">
          <el-input
            v-model="data.resume_job"
            type="textarea"
            :rows="6"
            @blur="onblur"
            @focus="onfocus"
            placeholder="请输入其他职位"
            :disabled="!modifyShow['resume_job'].content"
          ></el-input>
          <el-button
            v-if="
              !modifyShow['resume_job'].isUpdate &&
              modifyShow['resume_job'].button
            "
            size="small"
            @click="modify('resume_job')"
            >修改</el-button
          >
        </div>
        <div
          class="update_button_group"
          v-if="modifyShow['resume_job'].isUpdate"
        >
          <div class="update_button" @click="cancleUpdate('resume_job')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('resume_job')">
            保存
          </div>
        </div>
      </div>

      <div class="resumeInfo_row">
        <div class="resumeInfo_key">
          <span class="global_red_point">*</span>学术任职及其他
        </div>
        <div class="resumeInfo_value global_text_textarea">
          <el-input
            ref="doctorjobInput"
            v-model="data.resume_academic"
            type="textarea"
            placeholder=""
            :rows="6"
            @blur="onblur"
            @focus="onfocus"
            :disabled="!modifyShow['resume_academic'].content"
          >
          </el-input>
          <el-button
            v-if="
              !modifyShow['resume_academic'].isUpdate &&
              modifyShow['resume_academic'].button
            "
            @click="modify('resume_academic')"
            >修改</el-button
          >
          <span
            @click="setFocus"
            v-if="!data.resume_academic && config.showPlaceholder"
            class="doctorjobInput_placeholder"
            v-html="config.placeholder"
          ></span>
        </div>
        <div
          class="update_button_group"
          v-if="modifyShow['resume_academic'].isUpdate"
        >
          <div class="update_button" @click="cancleUpdate('resume_academic')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('resume_academic')">
            保存
          </div>
        </div>
      </div>

      <div class="resumeInfo_row resumeInfo_button_group">
        <customButton class="resumeInfo_button" size="small" @click="doCommit">
          <div class="resumeInfo_button_info">
            <img :src="require('@/assets/images/icon/apply.png')" alt="" />
            <span>提交简历信息</span>
          </div>
        </customButton>
      </div>
    </div>
    <!-- </itemScroll> -->
    <input
      type="file"
      @change="changeFile"
      ref="selectFile"
      v-if="manage.resetFile"
      v-show="false"
    />
    <imageCropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="manage.key == 'profile' ? 150 : 300"
      :flexScale="manage.key == 'profile' ? [1, 1] : [2, 1]"
      :isShowHeadimgCalibration="manage.key == 'profile'"
      v-if="manage.url"
    ></imageCropper>
  </div>
</template>
<script>
import { ElLoading, ElInput, ElButton, ElSelect, ElOption } from "element-plus";
import customButton from "@/components/unit/customButton";
// import itemScroll from "@/components/unit/itemScroll";
import imageCropper from "@/components/unit/imageCropper";
import weixin from "@/common/weixin";

export default {
  name: "resumeInfo",
  components: {
    // itemScroll: itemScroll,
    customButton: customButton,
    imageCropper: imageCropper,
    ElInput,
    ElButton,
    ElSelect,
    ElOption,
  },
  data() {
    return {
      projectInfo: {},
      data: {
        name: "",
        hospital: "",
        phone: "",
        profile: "",
        department: "",
        province: "",
        city: "",
        resume: "",
        job: "",
        resume_job: "",
        resume_academic: "",
      },
      splitReg: /[,\n]+/g,
      manage: {
        key: "",
        resetFile: true,
        url: "",
      },
      config: {
        citys: [],
        showPlaceholder: true,
        placeholder:
          "示例：<br>河南省医学会呼吸病学分会间质性肺疾病学组委员会<br>河南省医学会变态反应分会感染学组委员<br>河南省呼吸与危重症学会间质性肺疾病学分会副主任委员",
      },
      clickTime: 0,
      modifyField: [
        "name",
        "hospital",
        "phone",
        "department",
        "profile",
        "province",
        "city",
        "resume",
        "job",
        "resume_job",
        "resume_academic",
      ],
      cacheDataInfo: {},
      modifyShow: {
        name: {
          content: false,
          button: true,
          isUpdate: false,
        },
        hospital: {
          content: false,
          button: true,
          isUpdate: false,
        },
        phone: {
          content: false,
          button: true,
          isUpdate: false,
        },
        department: {
          content: false,
          button: true,
          isUpdate: false,
        },
        profile: {
          content: false,
          button: true,
          isUpdate: false,
        },
        province: {
          content: false,
          button: true,
          isUpdate: false,
        },
        city: {
          content: false,
          button: true,
          isUpdate: false,
        },
        resume: {
          content: false,
          button: true,
          isUpdate: false,
        },
        job: {
          content: false,
          button: true,
          isUpdate: false,
        },
        resume_job: {
          content: false,
          button: true,
          isUpdate: false,
        },
        resume_academic: {
          content: false,
          button: true,
          isUpdate: false,
        },
      },
      resumeOptions: ["副主任医师", "主任医师"],
    };
  },
  async created() {
    document.getElementsByTagName("title")[0].innerHTML = "简历信息";

    this.loadData();

    this.loadProjectInfo();
    this.config.citys = await this.$tools.getCityConfig();
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  methods: {
    isModifyShow() {
      for (const key of this.modifyField) {
        if (this.data[key] == "") {
          this.modifyShow[key].content = true;
          this.modifyShow[key].button = false;
        }
      }
    },
    doModify(key) {
      this.data[key] = "";
      this.modifyShow[key] = false;
      if (key == "province") {
        this.modifyShow["city"] = false;
      }
      setTimeout(() => {
        // this.initScroll();
      }, 100);
    },
    changeProvince() {
      this.data.city = "";
    },
    setFocus() {
      this.config.showPlaceholder = false;
      this.$refs.doctorjobInput.focus();
    },
    onblur() {
      this.$refs.doctorjobInput.blur();
      this.config.showPlaceholder = true;
    },
    onfocus() {
      this.config.showPlaceholder = false;
    },

    loadProjectInfo() {
      let url = this.$tools.getURL(this.$urls.admin.project, {
        project_id: this.$tools.getStorage("projectId", sessionStorage),
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.projectInfo = res.data;
        })
        .catch((err) => {});
    },
    loadData() {
      let url = this.$tools.getURL(
        this.$urls.admin.doctorInfo,
        this.$route.query
      );
      this.$axios.get(url).then((res) => {
        this.data = res.data;
        this.isModifyShow();
        for (let k in this.data) {
          if (this.data[k] == null) {
            this.data[k] = "";
          }
        }
        this.data.resume_academic = this.$tools
          .trimEnter(this.data.resume_academic)
          .replace(this.splitReg, "\n");
        this.data.project_id = this.$tools.getStorage(
          "projectId",
          sessionStorage
        );
        setTimeout(() => {
          // this.initScroll();
        }, 2000);
      });
    },
    initScroll() {
      // this.$refs.itemScroll.initScroll();
    },

    selectFile(key) {
      if (this.loading) return;
      this.manage.key = key;
      this.$refs.selectFile.click();

      // if (this.$tools.isLocal()) {
      //   this.$refs.selectFile.click();
      // } else {
      // this.receiveData({url:"https://kclass.imedunion.com/image/2/132/card/8e81d9522a8364539525c947a538e6ed_new.png"})
      // weixin
      //   .selectFile(1, 999999)
      //   .then((files) => {
      //     setTimeout(() => {
      //       this.loading = false;
      //     }, 1000);
      //     // this.data.doctorHeadImg = files[0]
      //     this.manage.url = files[0];
      //     this.$nextTick(() => {
      //       this.$refs.imageCropper.setData(this.manage.url);
      //     });
      //   })
      //   .catch((err) => {
      //     setTimeout(() => {
      //       this.loading = false;
      //     }, 1000);
      //   });
      // }
    },
    changeFile(event) {
      let file = event.target.files[0];
      if (!file) return;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (item) => {
        this.manage.url = item.target.result;
        this.manage.resetFile = false;
        this.$nextTick(() => {
          this.manage.resetFile = true;
          this.$refs.imageCropper.setData(this.manage.url);
        });
      };
    },
    receiveData(data) {
      this.manage.url = "";
      if (this.manage.key == "profile") {
        this.dealwithProfile(data.url);
      } else {
        this.data[this.manage.key] = data.url;
      }
    },

    dealwithProfile(url) {
      let loading = this.$tips.customLoading();
      let data = {
        profile: url,
      };
      this.$axios
        .post(this.$urls.doctor.image, data)
        .then((res) => {
          this.data[this.manage.key] = res.profile;
          this.manage.url = "";
          loading.close && loading.close();
          loading = null;
        })
        .catch((err) => {
          loading.close && loading.close();
          loading = null;
        });
    },
    isHide(key) {
      return String(this.data[key] || "").includes("*");
    },
    verifyData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入专家姓名" });
        return false;
      }
      if (!this.data.phone && !this.isHide("phone")) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1\d{10}$/g.test(this.data.phone) && !this.isHide("phone")) {
        this.$tips.error({ text: "请输入格式正确的手机号" });
        return false;
      }

      if (!this.data.province) {
        this.$tips.error({ text: "请输入专家省份" });
        return false;
      }
      if (!this.data.city) {
        this.$tips.error({ text: "请输入专家城市" });
        return false;
      }
      if (!this.data.hospital) {
        this.$tips.error({ text: "请输入专家医院" });
        return false;
      }
      if (!this.data.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      if (!this.data.resume) {
        this.$tips.error({ text: "请输入专家职称" });
        return false;
      }
      // if (!this.data.job) {
      //     this.$tips.error({ text: "请输入专家简历行政职务" });
      //     return false;
      // }
      // if (!this.data.resume_job) {
      //   this.$tips.error({ text: "请输入其他职位" });
      //   return false;
      // }
      if (!this.data.resume_academic) {
        this.$tips.error({ text: "请输入专家学术任职及其他" });
        return false;
      }
      if (!this.data.profile) {
        this.$tips.error({ text: "请上传专家简历头像" });
        return false;
      }

      let resumeAcademic = this.$tools
        .trimEnter(this.data.resume_academic)
        .split(this.splitReg);
      let resumeAcademicCount = 0;
      resumeAcademic.forEach((val) => {
        let textCount = Math.ceil(val.length / this.$config.resume.text);
        resumeAcademicCount += textCount;
      });
      if (resumeAcademicCount > this.$config.resume.row) {
        this.$tips.error({ text: "专家简历最多输入七行" });
        return false;
      }
      return true;
    },
    doCommit() {
      if (this.clickTime + 1500 > Date.now()) return;
      this.clickTime = Date.now();
      if (this.loading || !this.verifyData()) return;
      this.loading = true;
      const loadingPage = ElLoading.service({
        lock: true,
        text: "资料上传中,请稍后",
        background: "rgba(255, 255, 255, 0.5)",
      });
      let data = {
        id: this.data.id,
        project_id: this.$tools.getStorage("projectId", sessionStorage),
      };

      this.$axios
        .patch(this.$urls.admin.doctorInfoResume, {
          ...data,
          values: this.data,
        })
        .then((res) => {
          // setTimeout(()=>{ this.loading = false },1000)
          this.$tools.setStorage("isResumeInfo", 1, localStorage);
          this.$tips.success({ text: "完善成功" });
          loadingPage.close();
          // loadingPage = null
          this.$router.back();
        })
        .catch((err) => {
          loadingPage.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },

    checkData(key = "") {
      if ((key == "" || key == "name") && !this.data.name) {
        this.$tips.error({ text: "请输入专家姓名" });
        return false;
      }
      if ((key == "" || key == "phone") && !this.isHide(this.data.phone)) {
        if (!this.data.phone) {
          this.$tips.error({ text: "请输入手机号" });
          return false;
        }
        if (!/^1\d{10}$/g.test(this.data.phone)) {
          this.$tips.error({ text: "请输入格式正确的手机号" });
          return false;
        }
      }

      if ((key == "" || key == "province") && !this.data.province) {
        this.$tips.error({ text: "请输入专家省份" });
        return false;
      }
      if ((key == "" || key == "city") && !this.data.city) {
        this.$tips.error({ text: "请输入专家城市" });
        return false;
      }
      if ((key == "" || key == "hospital") && !this.data.hospital) {
        this.$tips.error({ text: "请输入专家医院" });
        return false;
      }
      if ((key == "" || key == "department") && !this.data.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      if ((key == "" || key == "resume") && !this.data.resume) {
        this.$tips.error({ text: "请输入专家职称" });
        return false;
      }

      // if ((key == "" || key == "resume_job") && !this.data.resume_job) {
      //   this.$tips.error({ text: "请输入其他职位" });
      //   return false;
      // }
      if (
        (key == "" || key == "resume_academic") &&
        !this.data.resume_academic
      ) {
        this.$tips.error({ text: "请输入专家学术任职及其他" });
        return false;
      }
      if ((key == "" || key == "profile") && !this.data.profile) {
        this.$tips.error({ text: "请上传专家简历头像" });
        return false;
      }

      let resumeAcademic = this.$tools
        .trimEnter(this.data.resume_academic)
        .split(this.splitReg);
      let resumeAcademicCount = 0;
      resumeAcademic.forEach((val) => {
        let textCount = Math.ceil(val.length / this.$config.resume.text);
        resumeAcademicCount += textCount;
      });
      if (resumeAcademicCount > this.$config.resume.row) {
        this.$tips.error({ text: "专家简历最多输入七行" });
        return false;
      }
      return true;
    },
    //只更改某一单独字段的值
    doCommitSingle(key) {
      if (!this.checkData(key) || this.isloading) return;
      let data = {
        id: this.data.id,
        project_id: this.$tools.getStorage("projectId", sessionStorage),
      };
      let values = {};
      values[key] = this.data[key];
      const loadingPage = ElLoading.service({
        lock: true,
        text: "资料上传中,请稍后",
        background: "rgba(255, 255, 255, 0.5)",
      });
      this.$axios
        .patch(this.$urls.admin.doctorInfoResume, { ...data, values })
        .then(() => {
          setTimeout(() => {
            loadingPage.close();
            this.modifyShow[key].content = false;
            this.modifyShow[key].isUpdate = false;
            // this.$refs.itemScroll.initScroll();
          }, 1000);
          this.$tips.success({ text: "修改成功" });
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            loadingPage.close();
          }, 1000);
        });
    },
    //取消修改
    cancleUpdate(key) {
      this.modifyShow[key].content = false;
      this.modifyShow[key].isUpdate = false;
      for (const doctorKey of this.modifyField) {
        this.data[doctorKey] = this.cacheDataInfo[doctorKey];
      }
      // this.$refs.itemScroll.initScroll();
    },
    modify(key) {
      for (const doctorKey of this.modifyField) {
        this.cacheDataInfo[doctorKey] = this.data[doctorKey];
      }
      this.modifyShow[key].content = !this.modifyShow[key].content;
      this.modifyShow[key].isUpdate = true;
      this.data[key] = "";
      // this.$refs.itemScroll.initScroll();
    },
  },
};
</script>
<style >
.resumeInfo_card_already_update.red_text {
  color: #f00;
}
.resumeInfo_container {
  position: relative;
  padding: 4vw;
  background: #fff;
  overflow: auto;
}
.resumeInfo_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2vw;
  position: relative;
}
.resumeInfo_key {
  margin-bottom: 2.4vw;
}
.resumeInfo_text_gray {
  margin-left: 2vw;
  color: red;
  font-size: 2vw;
}
.resumeInfo_card {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2.8vw 0;
}
.resumeInfo_card_none {
  width: 74.66vw;
  height: 37.33vw;
  border: 0.13vw solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resumeInfo_card_none_img_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resumeInfo_card_none_img_block span {
  color: #ccc;
  margin-top: 1vw;
}
.resumeInfo_card_none_img {
  width: 18.13vw;
  height: auto;
}
.resumeInfo_card_img {
  width: 100%;
  height: 100%;
}
.resumeInfo_button_group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.resumeInfo_button {
  width: 74.66vw;
  height: 9.4vw;
}
.resumeInfo_button_info {
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.resumeInfo_button_info img {
  height: 5.1vw;
  width: auto;
  margin-right: 1vw;
}
.resumeInfo_value {
  border: 1px solid #e6e6e6;
  padding: 0 0 0 4vw;
}
.doctorjobInput_placeholder {
  font-size: 3.5vw;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 2vw;
  color: #c4c4cc;
}
.doctor_profile {
  /* width: calc(30.4vw + 2px);
  height: calc(30.4vw + 2px);
  border: 1px solid #000; */
  display: flex;
  justify-content: center;
}
.doctor_background {
  background: #686868;
}
.doctor_profile img {
  border: 1px solid #0047ea;
  width: 30.4vw;
  height: 30.4vw;
  /* border-radius: 30.4vw; */
}
.resumeInfo_card_already_update {
  color: #686868;
}
.resumeInfo_note {
  color: #ccc;
  font-size: 2vw;
  margin-bottom: 2vw;
}
.resumeInfo_container .el-button {
  border: 1px solid #0047ea;
  border-radius: 1vw;
}
.el-textarea__inner {
  padding: 5px 0;
}
.update_button_group {
  padding: 2vw;
  display: flex;
  justify-content: flex-end;
}
.update_button_group :first-child {
  margin-right: 4vw;
}
.update_button {
  padding: 1vw 4vw;
  border-radius: 2vw;
  text-align: center;
  border: 1px solid #0047ea;
  color: #000;
}
</style>
